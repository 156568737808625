.sidebar {
    display: none; opacity: 0; width: 0px; 
    transition: width 1s ease-in;   
}
.content{
    display: none;
}
@keyframes slidediv {
    from{left: -50%; }
    to{left:0%; }
    }
@media screen and (max-width:992px){
    .side{
        position: fixed; color: aliceblue;
        top: 0; left: 0; z-index: 100;
        width: 100%; height: 100vh;
        opacity: .7; background-color: black;
        background-image: inherit; transition: 2s;
    }
    .content{
        width: 50%; height: 100vh; top: 50px; display: block; animation-name: slidediv; animation-direction:alternate;
        animation-duration: .3s; animation-play-state:running;
        animation-fill-mode:both; left: -50%;
        animation-timing-function:ease-in-out;
    }
    .content div{
        height: auto; font: 25px open; padding: 15px 0 15px 20px; width: 100%;
        border-bottom: lightgray solid 1px; color: white;
    }
    .content div a {
        color: #fff;  text-transform: capitalize;
        text-decoration: none; width: 100%;
        animation-name: slidediva; animation-direction: alternate;
        animation-duration: .2s; animation-play-state: running;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}
