@media screen and (max-width: 992px) {
  .body {
    width: auto;
  }
  .darken {
    height: 100vh;
    position: absolute;
    width: 100%;
    filter: brightness(40%);
    background-color: indigo;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  header {
    position: relative;
    height: 90vh;
    padding: 10px 10px 0 10px;
    background-image: inherit;
  }
  header .text {
    padding: 25vh 0 0 9% ;
    color: white;
  }
  header .text span:nth-child(1) {
    font: 60px open;
  }
  header .text span:nth-child(3) {
    font: 20px cursive;
  }
  header button {
    background-color: inherit;
    padding: 10px 40px;
    margin: 5vh 0 0 20%;
    border: none;
    color: whitesmoke;
    font-size: 20px;
    border: #fff 0.2px solid;
  }
  main {
    width: 99%;
    position: relative;
    top: 0vh;
    padding-top: 5px;
  }
  main .xyz {
    padding: 0 2%;
    width: auto;
    margin: auto;
    display: grid;
    grid-template-areas: 'bbb bbb' 'aaa aaa' 'ddd ddd' 'ccc ccc' 'eee eee';
    grid-template-rows: 400px auto auto auto 200px;
    grid-template-columns: 40% 60%;
    grid-gap: 5px;
    margin: 5px;
  }
  main .xyz .aaa {
    grid-area: aaa;
    display: none;
  }
  main .xyz .bbb {
    grid-area: bbb;
  }
  main .xyz .ddd {
    grid-area: ddd;
    background-color: #fff;
    display: none;
  }
  main .xyz .ddd div {
    height: 100%;
    width: 49%;
    background-color: grey;
    border: none;
  }
  main .xyz .ddd .ddd1 {
    margin-right: 0%;
    font-size: 25px;
  }
  main .xyz .eee {
    grid-area: eee;
    padding-top: 80px;
  }
  main .xyz .product {
    grid-area: ccc;
    background-color: #fea;
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    grid-row-gap: 10px;
  }
  main .xyz .product .item {
    width: 100%;
    flex: 24%;
    height: auto;
  }
  main .xyz .product .item div {
    height: 30%;
    color: #000;
    background-color: #fff;
    padding: 2px 4px;
    font: 15px open;
    color: white;
  }
  main .xyz .product .item div .name {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 17px;
  }
  main .xyz .product .item div .price {
    font-size: 25px;
    font-style: bold;
  }
  footer span {
    left: 40%;
    bottom: 1%;
  }
}
@media screen and (max-width: 576px) {
  main {
    padding-top: 2px;
  }
  main .xyz {
    padding: 0 0%;
    grid-template-rows: 300px auto 200px auto 200px;
  }
  main .xyz .aaa {
    grid-area: aaa;
    display: none;
  }
  main .xyz .bbb {
    grid-area: bbb;
  }
  main .xyz .ddd {
    grid-area: ddd;
  }
  main .xyz .eee {
    grid-area: eee;
  }
  main .xyz .product {
    grid-area: ccc;
    grid-row-gap: 10px;
  }
  main .xyz .product .item {
    width: 90%;
    flex: 45%;
    cursor: pointer;
    height: auto;
    margin: 5px;
  }
  main .xyz .product .item div {
    font: 15px open;
  }
  main .xyz .product .item div .name {
    font-size: 14px;
  }
  main .xyz .product .item div .price {
    font-size: 20px;
  }
}
