@media screen and (max-width:992px){
    nav{
        box-shadow: none;
        background-color:none;
        position: relative;
        z-index: 100;
        height: auto;
    }
    .brand{
        position: fixed;
        left: 0; z-index: 100;
        top: 0;width: 100vw;
        padding: 4px 0 0 10px;
        font-size: 25px;
        color: white; z-index: 1000;
        background-color: black;
        height: 50px;
    }
    .input{
        position: fixed;
        background-color: black;
        top: 0px;
        width: 96vw;
        height: 100vh;
        margin: auto  ;
        left: 0;
        display: none;
        z-index: 1000;
        display: none;
    }
    .input > input{
        padding: .4em;
        border: none;
        height: 40px;
        width: 90%;
        border-top-right-radius:2px ;border-bottom-right-radius:2px ;
    }
    .input >button{
        padding: .3em auto;
        width: 10%;
        height: 40px;
        border: none;
        background-color: white;
        border-top-left-radius:2px ;border-bottom-left-radius:2px ;
    }
    .links{
        height: 100vh; width: 60%; padding-top: 100px;
        position: fixed; top: auto;
        left: 0; z-index: 1000;
        font-size: 20px; display: none;
        background-color: black;
    }
    .links div{width: 100%; padding: 1em;
        border-bottom: lightgrey solid 1px;}
    .links a {
        padding-left:2em;color: white;
        text-decoration: none; 
        text-transform: uppercase;
    }
    .right{
        position: fixed;
        right:10% ; z-index: 1000;
        top: 5px; 
        font-size: 20px;
        color: white;
        background-color: inherit;
        border: none;
    }
    .d-sm{
        display: block;
    }
    
}