nav{
    position: relative;
    height: 8vh;
    background-image: inherit;
}
.brand{
    position: absolute;
    left: 3%;
    top: 10px;
    font-size: 30px;
    color: white;
}
.input{
    position: absolute;
    margin: 10px 0 0 0 ;
    left: 26%;
    border-radius: 3px;
    border: .4px solid black;
}
.input > input{
    padding: .4em;
    border: none;
    height: 40px;
    width: 300px;
    border-top-right-radius:2px ;border-bottom-right-radius:2px ;
}
.input >button{
    padding: .3em 10px;
    height: 40px; border: none;
    background-color: white;
    border-top-left-radius:2px ;border-bottom-left-radius:2px ;
    float: left;
}
.links{
    position: absolute;
    right: 7%; display: flex;
    top: 15px;
    font-size: 20px;
}
/* .links div */
.links a {
    padding-left:2em;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
}
.right{
    position: absolute;
    right: 2%;
    top: 10px;
    font-size: 20px;
    color: white;
    background-color: inherit;
    border: none;
}
.d-sm{
    display: none;
}